export const useRecentlyViewedItems = () => {

  const addItem = (id: number) => {
    const itemIds = getItemIds();

    const index = itemIds.indexOf(id);

    if (index > -1) {
      itemIds.splice(index, 1);
    }

    itemIds.unshift(id);

    if (itemIds.length > 10) {
      itemIds.pop();
    }

    useShopCookie().set('recentlyViewedItemIds', itemIds.join(','));
  }

  const getItemIds = () => {
    const result = useShopCookie().read('recentlyViewedItemIds')?.toString().split(',').filter(item => item).map(item => parseInt(item)) || [];

    return result;
  }

  return {
    addItem,
    getItemIds
  }

}